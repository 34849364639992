$main-color: #7C5BF6!default;
$white: #fff!default;
$black: #212529!default;
$gray-100: #F4F3F7!default;

$ui-bg-dark: #0F0E15;
$ui-surface: #22202C;
$ui-surface-2: #2E2C3A; //card-dark-gradient-violet-bg
$ui-surface-3: #3C394B;
$typography-secondary: #504d5a;
$typography-tertiary: #908d99;
$ui-dark-gradient: rgba(131, 226, 229, 0.1); //banner

$warning-text-bg: #9B6308;
$warning-text-border: #E9940C;
$warning-text: #FEF7EC;
$danger-text-bg: #6C0E19;
$danger-text-border: #901321;
$danger-text: #FDEDEF;

$transition: all .3s ease!default;


html[theme="dark"] {
  background: $ui-bg-dark;
}

body[theme="dark"] {
  background-color: $ui-bg-dark;
  color: $white;

  .dark-img {
    filter: invert(1) brightness(200%);
  }

  main {
    background: $ui-bg-dark;
    background: radial-gradient(80% 80% at 66.01% 106.38%, rgba(124, 91, 246, 0.5) 0%, rgba(46, 44, 58, 0) 100%), $ui-bg-dark;
  }
  
  .hover-bg {
    &:hover {
      background-color: rgba($gray-100, 0.1);
      transition: $transition;
    }
  }

  .holder {
    background-color: $ui-dark-gradient !important;

    &:after {
      background: linear-gradient(90deg, transparent, rgba(50, 50, 50, 0.8), transparent) !important;
    }
  }

  .mat-dialog-container, .mat-menu-content {
    background-color: $ui-surface-3;
    color: $white;
  }

  .mat-menu-item {
    color: $white;
  }

  .mat-form-field-outline {
    color: $typography-secondary; 
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline,
  .mat-input-element:disabled {
    color: $ui-surface-2;
  }

  mat-datepicker-content {
    background-color: $ui-surface-3;
    color: $white;
  }

  .mat-calendar-body-cell:hover .mat-calendar-body-cell-content {
    background-color: rgba($main-color, .35) !important;
    color: $white !important; 
  }

  .mat-datepicker-toggle {
    color: $white;
  }
  
  .mat-input-element {
    color: $white;

    &::placeholder {
      color: $typography-secondary;
      opacity: 1; 
    }

    &:disabled {
      color: $white;
    }
  }

  .mat-hint {
    color: $typography-secondary;
  }

  .mat-form-field-label {
    color: $white !important;
  }

  .mat-raised-button.mat-primary,
  .mat-flat-button.mat-primary,
  .mat-fab.mat-primary,
  .mat-mini-fab.mat-primary {
    
    &:disabled {
      background-color: $typography-secondary;
      pointer-events: none;
      color: $black;
    }
  }

  .mat-dialog-content {
    scrollbar-width: none;
  }

  hr {
    border-top: 1px solid rgba($white, .15);
  }

  app-toolbar { 
    .dark-connect-channel-button {
      background-color: $ui-surface-2;
      color: $white;
    }

    .mat-icon.icon-notifications {
      &:hover {
        color: $typography-secondary !important;
      }
    }

    .dark-channel-button.border {
      border: none !important;
      color: $white;
      background-color: $ui-surface-2;
    }

    .dark-user-menu-button.border {
      border-color: $typography-secondary !important;
    }

    small {
      color: $white;
    }
  }

  .mat-menu-panel {
    color: white !important;
    background-color: $ui-bg-dark;

    button:hover {
      background-color: $typography-secondary;
    }

    button:disabled {
      color: $typography-secondary;
      background-color: transparent !important;
    }
  }

  app-dialog-add-channel {
    color: $white;

    mat-card:hover {
      opacity: 0.8;
    }

    small {
      color: $white;
    }
  }


  .nav-item {
    img {
      filter: invert(1) brightness(200%);
    }
  
    a {
      &.active,
      &:not(.active):hover {
        background-color: $ui-surface;
      }
  
      &:not(.active):hover:before {
        background: $ui-surface;
      }
    }

    .holder {    
      &:after {
        width: 70%;
      }
    }
  }
  
  // main {
  //   background-color: red !important;
  // }

  .nav-main-mobile {
    color: $white;

    ul {
      background-color:  $ui-bg-dark !important;
    }

    img {
      filter: invert(1) brightness(200%);
    }

    a {
      &.active {
        background-color: $ui-surface;
      }

      &:not(.active) {
        &:hover {
          background-color: $ui-surface;

          &:before {
            background: $ui-surface;
          }
        }
      }
    }
  }

  .add-channel-mobile-button {
    color: $main-color; 
    background-color: $ui-surface; 
  }

  app-custom-notification > .custom-notification {
    background-color: $ui-dark-gradient;
    color: $white;
  }

  .page-container {
    background-color: $ui-surface;
  }

  .dark-wrapper.container {
    background-color: $ui-surface;
    color: $white;
  }

  .mat-stroked-button {
    border-color: $white;

    &:disabled {
      border-color: $typography-secondary;
      color: $typography-secondary;
    }
  }

  .mat-flat-button {
    &:hover {
      opacity: 0.8;
    }
  }

  app-feed { 
    .gradient-bg {
      background: radial-gradient(315% 158.77% at 100% 146.59%, rgba(126, 223, 223, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .mat-card.border {
    border-color: rgba($white, .15) !important;
  }

  .mat-card {
    background-color: transparent !important;
    color: $white;

    &.pirple-hover {
      &:hover {
        background: radial-gradient(349.63% 214.52% at 100% 151.42%, rgba(181, 163, 245, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }

    &.blue-hover {
      &:hover {
        background: radial-gradient(339.44% 179.55% at 100% 153.69%, rgba(126, 223, 223, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }

    &.green-hover {
      &:hover {
        background: radial-gradient(271.86% 192.06% at 95.88% 165.06%, rgba(125, 226, 152, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  app-money-boost {
    .button-wrapper {
      color: $white;
    }
  }

  .bg-body {
    background: $ui-surface-3 !important; 
  }

  .mat-select-panel {
    background: $ui-surface-2;

    .mat-option-text  {
      color: $white;
    }
  }
  
  .mat-select-value  {
    color: $white;
  }

  .mat-select-placeholder  {
    color: $white;
  }

  .alert {
    &.alert-warning {
      color: $warning-text;
      border: 1px solid rgba($warning-text-border, .65);
      background-color: $warning-text-bg !important;
    }

    &.alert-danger {
      color: $danger-text;
      border: 1px solid rgba($danger-text-border, .65);
      background-color: $danger-text-bg!important;
    }
  }  
  
  .balance-card {
    background: radial-gradient(327.45% 173.84% at -24.7% 164.63%, rgba(255, 255, 255, 0.25) 0%, rgba(46, 44, 58, 0) 100%),url('/assets/img/balance_bg.png');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    height: 188px;
    max-width: 492px;

    @media screen and (max-width: 576px) {
      background: radial-gradient(327.45% 173.84% at -24.7% 164.63%, rgba(255, 255, 255, 0.25) 0%, rgba(46, 44, 58, 0) 100%) !important;
      background-image: none;
    }
  }

  mat-tab-group {
    .mat-tab-label {
      &:hover {
        color: $white !important;
      }
    }

    .mat-tab-label-active h2 {
      color: $white !important;
    }

    .mat-tab-header-pagination-chevron {
      filter: invert(1) brightness(200%);
    }
  }

  .mat-spinner {  
    &.mat-dark {
      circle {
        stroke: rgba($white, .35);
      }
    }
  }

  .bg-white {
    background-color: $ui-surface !important; //Перекрыд bg-white 
  }

  mat-stepper {
    background-color: $ui-surface;
    
    .mat-stepper-vertical-line::before {
      border-color: $white;
    }

    .mat-step-text-label {
    color: $white;
    }
  }

  app-terms {
    .dark-tos {
      background-color: $ui-bg-dark !important;
    }

    .page-container-tos {
      background-color: $ui-surface;
    }
  }

  app-nav-channels {
    b {
      color: $white !important;
    } 
  }

  .border {
    border-color: rgba($white, .15) !important;
  }

  .nav-data-horizontal {
    .nav-item {
      border: 1px solid rgba($white, .15);
    
      &.active {
        border: none;
        background-color: #2e2c3a;
        background-image: radial-gradient(60% 40% at 90% 100%, rgba(124, 91, 246, 0.4) 0%, rgba(46, 44, 58, 0) 100%);
      }
    }
  }

  .mat-chip {
    background-color: $typography-secondary;
  }  
  
  .mat-slide-toggle-bar {
    background-color: $typography-secondary;
  }

  .mat-icon.text-muted {
    color: $white !important;
  }

  .mat-checkbox-frame {
    border-color: $ui-surface;
  }

  app-signin, app-sugnup, app-completing {
    .mat-checkbox-frame {
      border-color: $ui-surface-3;
    }
  }

  .hover-opacity {
    &:hover {
      .hover-opacity-el {
        opacity: 1!important;
      }
    }
  }

  .dark-custom-step {
    background-color: $ui-surface-3 !important;
    border-radius: 12px;
    padding-bottom: 20px;
  }

  .mat-expansion-panel-header, .mat-expansion-panel-header-title, .mat-expansion-panel-body {
    color: $white;
  }

  .mat-expansion-indicator::after {
    color: $white;
  }

  .mat-expansion-panel, .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background-color: transparent;
  }

  .card-description {
    background-color: $ui-surface-3 !important;
  }

  .audience-dark {
    background-color: $ui-surface-3 !important;
  }

  .analytics-item-buttons span {
    color: $white;
  }

  .mat-select-arrow {
    color: $typography-tertiary;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $typography-tertiary;
    border: none;
  }
    
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $ui-bg-dark inset !important;
    -webkit-text-fill-color: #ffffff;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    display: none;
  }
  
  .pagination {
    background: $ui-surface;
    -webkit-box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.6) !important;
    -moz-box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.6) !important;
    box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.6) !important;
    border: 1px solid rgba(255, 255, 255, 0.05) !important; 

    .text-dark {
      color: $white !important;
    }
  }

  .player-container{
    background-color: $ui-surface-2!important;
    color: $white !important;

    .border-bottom {
      border: 1px solid $typography-secondary !important;
    }

    mat-icon {
      color: $white !important;
    }
  }
}