@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// $my-typography: mat.define-typography-config(
//   $font-family:   'Roboto, "Helvetica Neue", sans-serif',
//   $display-4:     define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
//   $display-3:     define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
//   $display-2:     define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
//   $display-1:     define-typography-level(34px, 40px, 400),
//   $headline:      define-typography-level(24px, 32px, 400),
//   $title:         define-typography-level(20px, 32px, 500),
//   $subheading-2:  define-typography-level(16px, 28px, 400),
//   $subheading-1:  define-typography-level(15px, 24px, 400),
//   $body-2:        define-typography-level(14px, 24px, 500),
//   $body-1:        define-typography-level(14px, 20px, 400),
//   $caption:       define-typography-level(12px, 20px, 400),
//   $button:        define-typography-level(14px, 14px, 500),
//   $input:         define-typography-level(inherit, 1.125, 400)
// );

@include mat.core();

@font-face {
  font-family: 'Gilroy';
  src: url('/assets/gilroy/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/assets/gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/assets/gilroy/Gilroy-Bold.ttf') format('truetype'); /* Путь к файлу шрифта Gilroy Bold */
  font-weight: 900;
  font-style: normal;
}

// $font-family: 'Roboto, "Helvetica Neue", sans-serif' !default;
$font-family: 'Gilroy, Roboto, "Helvetica Neue", sans-serif' !default;
$body-1: mat.define-typography-level(1.05rem, 1.5, 300) !default;
$subheading-1: mat.define-typography-level(1.25rem, 1.2, 600) !default;
$headline: mat.define-typography-level(1.9rem, 1.2, 400) !default;
$subheading-2: mat.define-typography-level(1.12rem, 1.2, 400) !default;

$typography: mat.define-typography-config(
  $font-family: $font-family,
  $body-1: $body-1,
  $subheading-1: $subheading-1,
  $headline: $headline,
  $subheading-2: $subheading-2
);

@include mat.core($typography);


// Bootstrap
@import 'assets/bootstrap/bootstrap-utilities.min.css';
@import 'assets/bootstrap/bootstrap-grid.min.css';

// @import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

$main-color: #7C5BF6!default;
$white: #fff!default;
$black: #212529!default;
$body-background: #f6f5f9!default;
$body-color: #22202C!default;
$warning-text-bg: #664d03!default;
$warning-border: #ffecb5!default;
$yellow: rgba(255, 193, 7)!default;
$danger-text-bg: #881007!default;
$green: #3BB76D!default;
$gray-100: #F4F3F7!default;
$red: #f44336!default;
$muted: #6c757d!default;

$border-radius-main: 12px!default;

$transition: all .3s ease!default;

@keyframes animation-show {from {opacity: 0;} to {opacity: 1;}}
@keyframes loading {100% {transform: translateX(100%);}}

// Shared
html, body {
  height: 100%;
  background: $body-background;
  color: $body-color;
}

body {
  background: $body-background;
}

.container {
  max-width: 1024px;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: calc(100vh - 60px);
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

* {
  margin: 0;
  padding: 0;
}

.bg-main {
  background-color: $main-color;
}

.bg-body {
  background-color: $body-background!important;
}

a {
  color: $main-color;
  text-decoration: none;
  cursor: pointer;
}

.hover-opacity {
  &:hover {
    .hover-opacity-el {
      opacity: 1!important;
      transition: $transition;
    }
  }
}

hr {
  border: none;
  border-top: 1px solid rgba($black, .15);
}

.hover-weight {
  &:hover{
    font-weight: 600 !important;
  }
}

.button-wrapper {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  font: 300 1.05rem / 1.5 Gilroy, Roboto, "Helvetica Neue", sans-serif;

  &:disabled {
    opacity: .5;
  }

  .channels-button-hover {
    &:hover {
      border-color: $main-color !important;
    }
  }

  .mat-icon.icon-notifications {
    &:hover {
      color: $black !important;
    }
  }

  .user-button-hover {
    &:hover {
      border-color: #908d99 !important;
    }
  }
}

.notification {
  max-width: 50vw;

  @media screen and (max-width: 768px) {
   max-width: 100%;
  }
}

ul {
  &.non-style {
    list-style: none;
  }
}

a {
  &.non-style {
    color: inherit;
    text-decoration: none;
  }
}

.page-container {
  background-color: $white;
  border-radius: $border-radius-main;
  overflow-y: auto;
  position: relative;

  @media screen and (max-width: 576px) {
    width: 100%;
  }

  .page-container-small {
    width: 75%;

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

.tablet-page {
  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 0 107px 100px 107px;
  }
}

.payments-buttons-group {
  position: sticky;
  bottom: 0;
  z-index: 2;

  @media (min-width: 0) and (max-width: 1199px) {
    // padding-bottom: 150px;
    padding-bottom: 80px;
  }

  @media (min-width: 1200px)  {
    padding-bottom: 20px;
  }
}

.payments-list-mobile {
  @media screen and (max-width: 768px) {
    padding-bottom: 8rem;
  }
}

.holder {
  border-radius: $border-radius-main;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .65), transparent);
    animation: loading 0.8s infinite;
  }
}

.text-success {
  color: $green!important;
}

.remove-last-mp {
  &:nth-last-child(1) {
    margin-bottom: 0!important;
    padding-bottom: 0!important;
  }
}

.bg-holder {
  background-color: $gray-100;
}

.hover-bg {
  &:hover {
    background-color: rgba($gray-100, .65);
    transition: $transition;
  }
}

.gradient-bg {
  background: linear-gradient(to top left, rgba(128, 217, 217, 0.08) 0%, rgba(128, 217, 217, 0.03) 30%, rgba(128, 217, 217, 0) 40%, rgba(128, 217, 217, 0) 100%);
  // background: radial-gradient(circle at 100%, rgba(128, 217, 217, 0.3), rgba(255, 255, 255, 0));
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
// !Shared

// Material overriding
.mat-card,
.mat-select-panel,
.mat-button-base,
.rounded {
  border-radius: $border-radius-main!important;
}

.mat-card {
  &.payment-card {
    @media screen and (max-width: 767px) {
      border-radius: 16px 16px 0 0 !important;
    }
  }
  .card-description {
    height: 40px;
    background-color: #f6f5f9; 
    border-radius: 0 0 16px 16px;
  }

  &.pirple-hover {
    &:hover {
      background: radial-gradient(200% 160% at 180.35% 180.68%, rgb(181, 163, 245) 0%, rgb(255, 255, 255) 100%);
    }
  }

  &.blue-hover {
    &:hover {
      background: radial-gradient(200% 160% at 180.35% 180.68%, rgb(126, 223, 223) 0%, rgb(255, 255, 255) 100%);
    }
  }

  &.green-hover {
    &:hover {
      background: radial-gradient(200% 160% at 180.35% 180.68%, rgb(125, 226, 152) 0%, rgb(255, 255, 255) 100%);
    }
  }
}

.mat-button-lg {
  font-size: 1.05rem;
  line-height: 3.6 !important;
  font-weight: 400;
}

.mat-button-md {
  height: 56px;
  padding: 0 2rem!important;
  font-size: 16px;
}

.mat-button-sm {
  font-size: .8rem;
  line-height: 2.3 !important;
  font-weight: 400;
}

.mat-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: $main-color;

  &:hover {
    background-color: #f0fff3;
  }
}

.mat-raised-button.mat-primary,
.mat-flat-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: $white;
  background-color: $main-color;

  &:hover {
    background-color: #6946ec;
  }

  &:disabled {
    background-color: #efedf6;
  }
}

.mat-flat-button {
  &.white-border-hover {
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $main-color;
      color: $main-color;
    }
  }
}

.mat-icon-button.mat-primary {
  color: $main-color;
}

.mat-stroked-button {
  &.mat-button-base {
    border-color: $black;
  }

  &:disabled {
    border-color: rgba($black, .15);
  }
}

.mat-stroked-button.mat-button-base {
  .mat-button-focus-overlay {
    background-color:transparent;
  }

  &:hover {
    color: $main-color;
    border-color: $main-color;
    background-color: transparent;
  }
}

mat-slide-toggle {
  &.mat-primary.mat-checked {
    .mat-slide-toggle-bar {
      background-color: rgba($main-color, .55)!important;

      .mat-slide-toggle-thumb {
        background-color: $main-color!important;
      }
    }
  }
}

mat-checkbox {
  &.mat-checkbox-checked.mat-primary {
    .mat-checkbox-background {
      background-color: $main-color!important;
    }
  }
}

mat-pseudo-checkbox {
  &.mat-pseudo-checkbox-checked {
    background: $main-color !important;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $main-color;
}

.mat-spinner {
  &.mat-light {
    circle {
      stroke: rgba($white, .75);
    }
  }

  &.mat-dark {
    circle {
      stroke: rgba($black, .35);
    }
  }
}

.mat-menu-panel,
.mat-select-panel:not([class*=mat-elevation-z]),
.mat-dialog-container {
  border-radius: $border-radius-main!important;
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.mat-menu-panel {
  min-height: 52px!important;
}

mat-dialog-container {
  border-radius: $border-radius-main!important;
}

// Forms
mat-form-field {
  &.no-space {
    .mat-form-field-wrapper {
      margin: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .mat-form-field-label {
    padding-left: 1.5rem;
  }

  &.mat-form-field-hide-placeholder {
    .mat-form-field-label {
      padding-left: 1rem;
    }
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: 0.1em!important;

    .mat-icon {
      font-size: 130%;
    }
  }

  .mat-form-field-infix {
    padding: 1em 1rem 1em 1rem!important;
  }

  .mat-form-field-outline-start {
    border-radius: $border-radius-main 0 0 $border-radius-main !important;
    min-width: 12px !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0 $border-radius-main $border-radius-main 0 !important;
    min-width: 12px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 18px !important;
  }

  &.fixed-search, &.select-sm {
    .mat-form-field-prefix {
      top: 0.3em !important;
    }

    .mat-form-field-infix {
      border-top: 0.45em solid transparent;
    }
  }

  &.pill {
    .mat-form-field-outline-start {
      border-radius: 25px 0 0 25px !important;
      min-width: 50px !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 25px 25px 0 !important;
      min-width: 50px !important;
    }
  }
}

.mat-form-field-appearance-outline.mat-focused {
  .mat-form-field-outline-thick, mat-label {
    color: $main-color;
  }
}

mat-radio-button {
  &.multi-line {
    .mat-radio-label {
      white-space: break-spaces;
      display: flex;
      align-items: start;
      justify-content: start;
      margin: inherit;
    }

    .mat-radio-container {
      margin-top: 2px;
    }
  }

  &.mat-radio-checked {
    .mat-radio-outer-circle {
      border-color: $main-color!important;
    }

    .mat-radio-inner-circle {
      background-color: $main-color!important;
    }
  }

}

// !Forms

.cdk-overlay-container {
  z-index: 99999999;
}

// Custom Snackbar
// .cdk-overlay-pane {
//   .mat-snack-bar-container {
//     height: 64px;
//     width: 1020px;
//     margin-bottom: 5rem !important;
//     background-color: $white !important;
//     box-shadow: 0 4px 32px 0 rgba(10, 6, 23, 0.08);
//     border-radius: 12px;
//     color: $black;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 143%;
//     margin-left: 335px; //Исправить

//     &.error {
//       border: 1px solid $red;
//     }

//     &.warning {
//       border: 1px solid $yellow;
//     }

//     &.success {
//       border: 1px solid $green;
//     }

//     &.delete {
//       border: 1px solid $muted;
//     }
//   }
// }

.cdk-overlay-pane {
  .mat-snack-bar-container {
    background: rgba(50, 50, 50, .9);
    max-width: 100% !important;
    margin-bottom: 5rem !important;

    &.mat-snack-bar-center {
      max-width: 80vw;
      border-radius: 24px;
    }

    .mat-simple-snackbar-action {
      color: $white;
    }
  }

  &.no-gutter-dialog {
    mat-dialog-container {
      padding: 0;
    }
  }

  &.dialog-text-left {
    text-align: left;
  }

  &.dialog-text-center {
    text-align: center;
  }
}

.mat-chip {
  &.mat-standard-chip {
    background-color: $gray-100;
  }
}

.mat-ripple {
  .mat-ripple-element {
    background-color: rgba($main-color, .03)!important;
  }

  &.ripple-white-bg {
    .mat-ripple-element {
      background-color: rgba($main-color, .2)!important;
    }
  }
}

mat-tab-group {
  .mat-tab-label {
    padding: 0;
    margin-right: 2rem;
    min-width: 80px;
    color: $muted!important;
    opacity: 1;

    &:hover {
      color: $black !important;
    }
  }

  mat-ink-bar, .mat-ink-bar {
    height: 4px;
    border-radius: 2px 2px 0 0;
  }

  &.mat-primary {
    mat-ink-bar, .mat-ink-bar {
      background-color: $main-color!important;
    }
  }

  .mat-tab-header-pagination {
    &.mat-elevation-z4 {
      box-shadow: none!important;
    }
  }

  .mat-tab-header {
    @media screen and (max-width: 1199px) {
      border-bottom: none;
    }
  }

  .mat-tab-label-active h2 {
    font-weight: 600!important;
    color: $black!important;
  }
  
}

.mat-datepicker-toggle-active {
  color: $main-color!important;
}

mat-datepicker-content {
  border-radius: 24px!important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

  .mat-calendar-body-cell {
    &:hover, &.mat-calendar-body-active {
      .mat-calendar-body-cell-content {
        background-color: rgba($main-color, .35)!important;
      }
    }
  }
}

mat-chip-list {
  &.no-hover {
    mat-chip.mat-standard-chip {
      user-select: none;
      
      &:after {
        display: none;
      }

      &:hover, &:focus, &.active {
        background-color: #F4F3F7!important;
        box-shadow: none!important;
      }

      &.stracked {
        max-width: max-content;
        height: max-content;
      }
    }
  }
}

mat-stepper {
  mat-step-header {
    border-radius: $border-radius-main;

    .mat-step-icon {
      background-color: $body-background!important;
      color: $muted!important;
      font-size: small;

      .mat-step-icon-content {
        top: 57%;
      }

      &.mat-step-icon-selected {
        background-color: $main-color!important;
        color: $white!important;
      }

      &.mat-step-icon-state-edit {
        mat-icon {
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }

    .mat-step-label {
      .mat-step-text-label {
        margin-top: 3px;
      }
    }
  }

  [aria-labelledby="awx-wrapper"] + .mat-vertical-content-container {
    .mat-vertical-content {
      padding: 0 12px 12px 12px;
    }
  }
}

// !Material overriding

/* Google Maps */
.pac-container {
  z-index: 9999;
  // box-shadow: 0 1px 0 rgb($black, .06);
  border: none;
  border-bottom-right-radius: $border-radius-main;
  border-bottom-left-radius: $border-radius-main;
  margin-top: 8px;
  box-sizing: content-box;
  padding-right: 0px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

  .pac-item, .pac-item-query {
    font-size: .935rem;
  }

  .pac-item {
    padding: 5px 15px 5px 15px;
    border: none;
    color: $black;

    .pac-matched {
      color: $black;
      text-decoration: underline;
    }

    .pac-icon {
      display: none;
    }

    &:hover {
      background-color: #f9fafc;
    }
  }
}

.pac-container:after {
  background-image: none!important;
  height: 0px;
}
/* !Google Maps */

.nav-data-horizontal {
  .nav-item {
    border: 1px solid rgba($black, .15);
    border-radius: $border-radius-main;
    cursor: pointer;
    width: 110px;
    position: relative;

    &.active {
      border: 1px solid $white;
      background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba($main-color, 0.08) 70%, rgba($main-color, 0.15) 100%);
      overflow: hidden!important;
      transition: $transition;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        height: 26px;
        width: 3px;
        background: $main-color;
        border-radius: 5px;
        opacity: 0;
        animation: animation-show 0.15s ease-in forwards;
      }
    }
  }
}

.nav-main {
  width: 280px;
  
  .nav-item {
    a {
      border-radius: $border-radius-main;
      position: relative;
      overflow: hidden;

      &.active {
        background-color: $white;
        font-weight: 600;
        transition: $transition;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          height: 21px;
          width: 3px;
          background: $main-color;
          border-radius: 5px;
          -webkit-box-shadow: 0 0 105px 18px $main-color;
          -moz-box-shadow: 0 0 105px 18px $main-color;
          box-shadow: 0 0 105px 18px $main-color;
          opacity: 0;
          animation: animation-show 0.15s ease-in forwards;
        }
      }

      &:not(.active) {

        &:before {
          opacity: 0;
        }

        &:hover {
          background-color: $white;
          transition: $transition;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 21px;
            width: 3px;
            background: $main-color;
            border-radius: 5px;
            -webkit-box-shadow: 0 0 105px 18px $main-color;
            -moz-box-shadow: 0 0 105px 18px $main-color;
            box-shadow: 0 0 105px 18px $main-color;
            opacity: 1;
            animation: animation-show 0.15s ease-in forwards;
          }
        }
      }
    }
  }
}

.nav-main-mobile {
  .nav-item-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 100%;
    font-size: 10px;
    font-weight: 600;
    line-height: 140%;
    
    a {
      border-radius: $border-radius-main $border-radius-main 0 0;
      position: relative;
      overflow: hidden;
      height: 100%;
      width: 100%;
      
      &.active {
        background-color: $white;
        font-weight: 600;
        transition: $transition;

        &:before {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 0;
          height: 3px;
          width: 28px;
          background: $main-color;
          border-radius: 0 0 2px 2px;
          -webkit-box-shadow: 0 0 105px 18px $main-color;
          -moz-box-shadow: 0 0 105px 18px $main-color;
          box-shadow: 0 0 105px 18px $main-color;
          opacity: 0;
          animation: animation-show 0.15s ease-in forwards;
          overflow: hidden;
        }
      }

      &:not(.active) {
        &:hover {
          background-color: $white;
          transition: $transition;
        }

        &:before {
          opacity: 0;
        }
      }
    }
  }
}

.alert {
  font-size: 95%;
  --bs-bg-opacity: .3;

  &.alert-warning {
    color: $warning-text-bg;
    border: 1px solid rgba($yellow, .65);
    background-color: #FFF3CD!important;
  }

  &.alert-danger {
    color: $danger-text-bg;
    border: 1px solid rgba($red, .65);
    background-color: #FDDBD8!important;
  }
}

.notification-indicator {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: $red;
}

.html-content {
  strong {
    font-weight: 800;
  }

  ul {
    margin-left: 1.5rem;
  }
}

.custom-table {
  .thead {
    .tr {
      .th {
        color: $muted;
        text-transform: uppercase;
        font-size: 80%;
      }
    }
  }
  .tbody {
    line-height: normal;

    .td {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

.scrollbar-hidden {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}


.mat-expansion-panel {
  .mat-expansion-panel-header {
    padding: 0 5px 0 0 !important;

  }

  .mat-expansion-panel-body {
    padding-bottom: 0 !important;
    // padding: 0;
  }
}

.page-container-tos {
  background-color: $white;
  border-radius: $border-radius-main;
  overflow-y: auto;
  width: 75%;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.article-space {
  & ol {
    padding-left: 2rem;
  }
}

.social-group {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 12px;
  right: -15px;
  z-index: 999;
  pointer-events: none;
  
  & a {
    height: auto !important;
    margin: 5px 0;
  }

  & .icon-animation {
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1);  
    }
  }

  @media screen and (max-width: 1199px) {
    bottom: 45px;
    right: -25px
  }
}

@import './dark.scss';