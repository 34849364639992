// body, body * {
//   transition: all .3s ease;
// }

body[theme="dark"] {
  background-color: red;

  app-toolbar > div > div {
    background-color: green;
    // transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
  }
}